<template>
  <div>
    <v-row>
      <v-col
        v-for="(item, index) in products"
        :key="index"
        cols="12"
      >
        <div class="card-content">
          <div class="display-flex">
            <div class="content-image">
              <img
                v-if="item.sImageUrl !== ''"
                :src="item.sImageUrl"
                alt=""
              >
              <img
                v-else
                src="@/assets/images/placeholder-image.jpeg"
                alt=""
              >
            </div>
            <div class="ml-2 width-100">
              <p
                style="width: 90%; overflow-wrap: break-word;"
                class="text-product-name mon-bold"
              >{{ item.sProductName }}</p>
              <div class="display-flex mb-1">
                <v-icon
                  color="#b2b2b2"
                  size="16px"
                >
                  mdi-account
                </v-icon>
                <p class="text-provider-name mon-regular">{{ item.sProviderName }}</p>
              </div>
              <div class="display-flex">
                <v-icon
                  color="#b2b2b2"
                  size="16px"
                >
                  mdi-map-marker
                </v-icon>
                <p class="text-provider-location mon-regular">{{ item.sStateName }}. {{ item.sCountryName }}</p>
              </div>
              <div class="display-flex align-items-center justify-content-flex-start">
                <p class="text-unit-price mon-regular">${{ formatMoney(item.dUnitPrice) }} USD</p>
              </div>
              <div class="display-flex align-items-center justify-content-flex-start mt-1">
                <div>
                  <input
                    v-model="products[index].dQuantity"
                    class="input-amount"
                    type="text"
                    :ref="item.sProductId"
                    @keypress="soloNumbers"
                    @keyup="ajustAmount(item.sProductId, products[index].dQuantity, item.originQuantity)"
                    @blur="noEmpty(item.sProductId)"
                    :min="1"
                    maxlength="6"
                  >
                  <v-btn
                    v-if="products[index].dQuantity != item.originQuantity"
                    class="button-modify mon-medium ml-2"
                    elevation="0"
                    @click="modifyQuantity(item.sProductId)"
                  >
                    {{ texts.shoppingcart.textButtonModify }}
                  </v-btn>
                  <p class="text-max-boxes mon-regular">
                    Max: <strong>{{ item.dInventoryQuantity }}</strong>
                  </p>
                </div>
              </div>
              <div class="display-flex align-items-center justify-content-flex-end">
                <p class="text-total-price mon-bold mr-4">${{ formatMoney(item.dTotalPrice) }} USD</p>
              </div>
            </div>
          </div>
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="setDialogDelete(item.sProductId)"
            style="position: absolute; top: 10px; right: 10px;"
          >
            <v-icon
              color="#000"
              size="25px"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ShoppingCartTabletUiLayout",
  props: {
    texts: {
      type: Object,
    },
    products: {
      type: Array,
    },
  },
  data() {
    return {
      //VARIABLES
    };
  },
  methods: {
    minAmount: function (id) {
      if (this.$refs[id][0].value > 1) {
        this.$refs[id][0].value--;
      }
    },
    maxAmount: function (id) {
      if (this.$refs[id][0].value < 100) {
        this.$refs[id][0].value++;
      }
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    ajustAmount: function (id, oldVal, newVal) {
      if (this.$refs[id][0].value == "00") {
        this.$refs[id][0].value = 1;
      } else if (this.$refs[id][0].value == "0") {
        this.$refs[id][0].value = 1;
      }

      if (oldVal != newVal) {
        this.$emit("validateChange", true);
      } else {
        this.$emit("validateChange", false);
      }
    },
    noEmpty: function (id) {
      if (this.$refs[id][0].value == "") {
        this.$refs[id][0].value = 1;
      }
    },
    modifyQuantity: function (id) {
      const payload = { dQuantity: this.$refs[id][0].value };
      const config = {
        headers: { Authorization: `Bearer ${this.$store.state.sToken}` },
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/customers/${this.$store.state.sUserId}/shopping-cart/${id}`,
        payload,
        config
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.$emit("validateChange", false);
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    setDialogDelete: function (id) {
      this.$store.commit("setDialogDelete", {
        active: true,
        title: this.texts.shoppingcart.delete.textTitle,
        api: `${URI}/api/v1/${this.selectLanguage}/customers/${this.$store.state.sUserId}/shopping-cart/${id}`,
        redirect: "",
      });
    },
    formatMoney(money) {
      //return number in formay money
      return FORMAT_MONEY(money);
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    products: function () {},
  },
};
</script>

<style scoped>
.input-amount {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  opacity: 1;
  height: 35px;
  font-size: 14px;
  width: 60px;
  outline: none;
  text-align: center;
  padding: 5px;
}

/* Chrome, Safari, Edge, Opera */
.input-amount::-webkit-outer-spin-button,
.input-amount::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-amount[type="number"] {
  -moz-appearance: textfield;
}

.button-modify {
  background: transparent linear-gradient(263deg, #ffc431 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
  font-size: 12px;
  text-transform: initial;
  height: 30px !important;
}

.text-total-price {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-max-boxes {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 5px;
  margin-bottom: 0px;
}

.text-unit-price {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-product-name {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-provider-name {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #b2b2b2;
  opacity: 1;
  margin-bottom: 0px;
}

.text-provider-location {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #b2b2b2;
  opacity: 1;
  margin-bottom: 0px;
}

.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  opacity: 1;
  padding: 15px;
  position: relative;
}

.content-image {
  width: 220px;
  height: 150px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border-radius: 10px;
  }
}
</style>